<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
          <el-option label="上架中" :value="2"></el-option>
          <el-option label="下架" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addActivity">新增活动</el-button>
      </el-form-item>
    </el-form>
     
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">下架</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 2"
            >上架中</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="updateInfo(row)"
            >编辑</el-button
          >
           <el-button v-if="row.type != 'couponPackage'"  type="success" size="small" @click="toCourse(row)"
            >活动课程</el-button
          >
          <el-button  v-if="row.type == 'couponPackage'" type="success" size="small" @click="toCoop(row)"
            >参加合作商</el-button
          >
            <el-button v-if="row.status == 1" type="success" size="small" @click="updateStatus(row,2)"
            >上架</el-button
          >
            <el-button v-if="row.status == 2"  type="warning" size="small" @click="updateStatus(row,1)"
            >下架</el-button
          >
          <el-button  type="danger" size="small" @click="del(row)"
            >删除</el-button
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
         { name: "id", value: "id" },
        { name: "活动名称", value: "name" },
        { name: "活动标题", value: "title" },
        { name: "状态", value: "status", type: "custom" },
        { name: "活动时间", value: "activity_time", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 2,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    toCoop(row){
        this.$router.push({ name: "settingCooperator", query: { aid: row.id } });
      
    },
    toCourse(row){
         this.$router.push({ name: "activityCourseManage", query: { aid: row.id,tag:row.tags } });
    },
    
    addActivity() {
      this.$router.push({ name: "addActivity" });
    },
    updateInfo(row) {
      this.$router.push({ name: "addActivity", query: { aid: row.id } });
    },
    del(row){
      this.$confirm("确定删除").then(()=>{
         this.$post('/user/businessActivityType/update',{id:row.id,status:3}).then(res=>{
          this.getList(1);
        })
      })
    },
    updateStatus(row,status){
        if(status == 2){
          if(new Date(row.end_time).getTime() < Date.now()){
            this.$message("活动已结束，不能上架")
            return
          }
        }

        this.$post('/user/businessActivityType/update',{id:row.id,status}).then(res=>{
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/businessActivityType/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>